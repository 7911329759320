<template>
  <div class="modale-block" v-if="revele">
    <div class="overlay" @click.prevent="() => toggle()"></div>
    <div
      class="modale card"
      style="
        max-width: 50rem;
        min-height: 600px;
        max-height: 800px;
        overflow: auto;
      "
    >
      <button @click.prevent="() => toggle()" class="btn btn-danger btn-modale">
        &times;
      </button>
      <div class="card-body">
        <h1>Editer la partie {{ title }}</h1>
        <form @submit.prevent="apply">
          <label class="text-label" for="title"
            >Sous titre <span class="text-danger">*</span>:</label
          >
          <input
            class="form-control mb-2"
            id="title"
            type="text"
            v-model="partContent.title"
            placeholder="Sous titre"
          />
          <label class="text-label" for="video"
            >Video <span class="text-danger">*</span>:</label
          >
          <textarea
            v-model="partContent.video"
            class="form-control"
            id="video"
            placeholder="Video's code..."
          ></textarea>
          <label class="text-label" for="resume">Resume:</label>
          <QuillEditor
            v-if="this.isCreation"
            theme="snow"
            v-model:content="partContent.text"
            contentType="html"
            toolbar="full"
            id="resume"
          />

          <div id="editPartText"></div>

          <p v-if="partContent.ressource">
            {{ partContent.ressource.name }}
          </p>
          <input
            @change="loadResource"
            style="display: none"
            id="resource"
            type="file"
            accept="image/*,.pdf, .doc,.docx, .odt, .xls, .pptx"
          />
          <h6
            v-if="partContent.resource"
            class="mt-2 text-center"
            style="font-size: 15px; text-decoration: underline"
          >
            {{ partContent.resource.name }}
          </h6>
          <label class="mt-2 mb-2 d-block btn btn-info btn-sm" for="resource"
            ><i class="fas fa-upload"></i> &nbsp;resource</label
          >
          <button
            type="submit"
            class="btn mt-2 btn-success"
            :disabled="partContent.title == '' || partContent.video == ''"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
// import { quillEditor } from "vue-quill-editor";
import { Quill, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
export default {
  data() {
    return {
      title: "",
      editQuill: null,
      isCreation: true,
      partContent: {
        title: "",
        video: "",
        text: "",
        ressource: null,
        coursePart: this.partId,
      },
      editorOption: {},
    };
  },
  components: {
    QuillEditor,
  },
  props: {
    revele: Boolean,
    toggle: Function,
    partId: Number,
    data: Object,
    onSaveSucces: Function,
  },
  methods: {
    async apply() {
      const fd = new FormData();
      let begin = this.partContent.video.indexOf("<iframe");
      let video = this.partContent.video;
      if (begin != -1)
        video =
          "<div>" +
          this.partContent.video.substring(begin, this.partContent.length);
      fd.append("title", this.partContent.title);

      fd.append("text", this.partContent.text);
      fd.append("video", video);
      fd.append("course_part", this.partContent.coursePart);
      if (this.partContent.ressource != null)
        fd.append(
          "resource",
          this.partContent.ressource,
          this.partContent.ressource.name
        );

      if (this.data == null || this.data.id < 1) {
        await this.$http
          .post(`/courses/course-content/`, fd)
          .then(() => {
            this.$toast.success("Upload success!");
            this.onSaveSucces();
            return this.toggle();
          })
          .catch(() => {
            this.$toast.error("An error occured! Please try egain!");
          });
      } else {
        // console.log("The quill", this.editQuill.root.innerfHTML);
        var quillContent = this.editQuill.root.innerHTML;
        fd.append("text", quillContent);

        await this.$http
          .put(`/courses/course-content/update/${this.data.id}/`, fd)
          .then(() => {
            this.$toast.success("Upload success!");
            this.onSaveSucces();
            return this.toggle();
          })
          .catch(() => {
            this.$toast.error("An error occured! Please try egain!");
          });
      }
    },
    async loadPart() {
      if (this.revele && this.partId > 0)
        await this.$http
          .get(`/courses/course-part/${this.partId}/`)
          .then((res) => (this.title = res.data.title))
          .catch(() => {
            this.$toast.error("An error occured!");
          });
      if (this.data != null && this.data != undefined && this.data.id > 0) {
        this.loadPartContent(this.data.id);
      }
    },
    async loadPartContent(id) {
      await this.$http
        .get(`/courses/course-content/${id}/`)
        .then((response) => {
          this.partContent = response.data;
          // console.log("Part content:", this.partContent);
          this.isCreation = false;
          this.createQuillForEdit();
        });
    },
    loadResource(event) {
      this.partContent.ressource = event.target.files[0];
    },
    createQuillForEdit() {
      var editElement = document.getElementById("editPartText");
      // console.log("Elelement", editElement);
      var toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ];

      this.editQuill = new Quill(editElement, {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
      });
      // console.log("The part in quiill creation: ", this.partContent.text);
      this.editQuill.root.innerHTML = this.partContent.text;
    },
  },
  beforeMount() {
    this.$nextTick(function () {
      this.loadPart();
      //after that, we have already load the course part content
    });
  },
  mounted() {
    // console.log("IsCreation: ", this.isCreation);
    // if (!this.isCreation) {
    //   this.createQuillForEdit();
    // }
  },
};
</script>
<style lang="css" scoped>
.modale-block {
  z-index: 999999; /* le derangement! */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
iframe {
  border: none;
}
.modale {
  background-color: #f1f1f1;
  color: #333;
  position: fixed;
  padding: 20px;
  top: 20px;
  bottom: 20px;
}
.btn-modale {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
