<template>
  <div class="main-wrapper">
    <Navbar />
    <EditPartModale
      v-if="reveleModale"
      :onSaveSucces="loadParts"
      :revele="reveleModale"
      :partId="indexedPart"
      :toggle="toggleModale"
      :data="selectedContent"
    />
    <div class="container-fluid">
      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ category }}
                  </li>
                </ol>
              </nav>
              <h1 class="breadcrumb-title">{{ course.title }}</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
    </div>
    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="blog-view">
              <div class="blog blog-single-post">
                <div class="embed-responsive embed-responsive-16by9">
                  <div v-html="course.introVideo"></div>
                </div>
                <div class="mt-2 blog-info clearfix">
                  <div class="post-left">
                    <button
                      v-if="accessible || isTeacher"
                      class="btn btn-success"
                      @click="beginCourse"
                    >
                      <span class="fas fa-eye"></span>
                      {{ !isTeacher ? "commencer le cours" : "Visualiser" }}
                    </button>
                    <router-link
                      :to="{
                        name: 'InitPayment',
                        params: { idCourse: $route.params.idCourse },
                      }"
                      v-if="!accessible && !isTeacher"
                      class="btn btn-success"
                      ><span class="fas fa-coins"></span> Payer
                      {{ course.price }}
                      {{ course.currency }}
                    </router-link>
                  </div>
                </div>
                <br />
                <br />

                <h3 class="text-primary">Decription du cours</h3>

                <p class="m-3" v-html="course.description"></p>
                <div class="blog-content">
                  <div class="container"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- Blog Sidebar -->
          <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
            <!-- Latest Posts -->
            <div class="card post-widget">
              <div class="card-header">
                <h4 class="card-title text-center">
                  {{ $t("ShowCourseDetails.TABLE_CONTENT") }}
                </h4>
              </div>
              <div
                class="accordion"
                id="accordionExample"
                v-for="(part, i) in partCourse"
                :key="part.id"
              >
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0 d-flex justify-content-between">
                      <button
                        class="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        :data-target="'#collapse' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {{ i + 1 }}- {{ part.title }}
                      </button>
                      <div v-if="$store.getters.userGroup == 'admin'">
                        <button
                          @click.prevent="() => deletePart(part.id)"
                          class="ml-2 btn btn-danger btn-sm"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                        <button
                          @click="() => changeEditPartId(part.id)"
                          class="ml-2 btn btn-success btn-sm"
                          title="Ajouter un contenu dans la partie"
                        >
                          <i class="fas fa-folder-plus"></i>
                        </button>
                      </div>
                    </h5>
                  </div>

                  <div
                    :id="'collapse' + i"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <ul class="ml-3 list-group">
                        <li
                          v-for="(c, j) in part.coursecontents"
                          :key="c.id"
                          class="list-group-item"
                        >
                          <span v-if="$store.getters.userGroup == 'admin'">
                            <span
                              class="text-primary text-link"
                              @click="() => setSelectedContent(c, part.id)"
                              >{{ i + 1 }}.{{ j + 1 }} {{ c.title }}</span
                            >
                            <button
                              class="badge badge-danger"
                              @click="deletePartContent(c)"
                            >
                              <i class="far fa-trash-alt"></i>
                            </button>
                          </span>
                          <span v-else>
                            {{ i + 1 }}.{{ j + 1 }} {{ c.title }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Latest Posts -->
            <div class="card author-widget clearfix" v-if="!isTeacher">
              <div class="card-header">
                <h4 class="card-title">
                  {{ $t("ShowCourseDetails.A_PROPOS_ENSEIGNANT") }}
                </h4>
              </div>
              <div class="card-body">
                <div class="about-author">
                  <div class="about-author-img">
                    <div class="author-img-wrap">
                      <span
                        ><img
                          class="rrounded-circle"
                          alt=""
                          :src="course.teacher.profilePicture.thumbnail"
                          width="60"
                          height="60"
                      /></span>
                    </div>
                  </div>
                  <div class="author-details">
                    <span class="blog-author-name"
                      >{{ course.teacher.firstName }}
                      {{ course.teacher.lastName }}</span
                    >
                    <p>
                      <small
                        ><em>{{
                          course.teacherProfile.qualification
                        }}</em></small
                      >
                    </p>
                    <p class="mb-0">
                      {{ course.teacherProfile.about }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$store.getters.userGroup == 'admin'">
              <div class="card author-widget clearfix form-group p-2">
                <h3 class="text-center">
                  {{ $t("ShowCourseDetails.AJOUTER_PARTIE") }}
                </h3>
                <label for="title"
                  >{{ $t("ShowCourseDetails.TITRE") }}
                  <span class="text-danger">*</span>:</label
                >
                <input
                  type="text"
                  v-model="titleofpart"
                  :placeholder="$t('ShowCourseDetails.TITRE') + '...'"
                  class="form-control"
                  :class="getClassTitle"
                  name="title"
                  id="title"
                />
                <button
                  class="btn btn-primary mt-2"
                  :disabled="titleofpart == ''"
                  @click="addCoursePart()"
                  title="Ajouter un contenu dans la partie"
                >
                  Ajouter
                </button>
              </div>
              <div class="card author-widget clearfix form-group p-2">
                <h3 class="text-center">
                  {{ $t("ShowCourseDetails.IMAGE_DU_COURS") }}
                </h3>
                <form class="text-center" @submit.prevent="uploadImage">
                  <img
                    :class="loadedImage != null ? 'img-preview' : ''"
                    :src="
                      loadedImage == null
                        ? course.coursePicture.thumbnail
                        : loadedImage
                    "
                    :alt="course.title"
                  /><br />
                  <label class="mt-2 btn btn-info btn-sm" for="picture"
                    ><i class="fa fa-upload"></i>
                    {{ $t("ShowCourseDetails.CHARGER") }}</label
                  >
                  <input
                    @change="loadImage"
                    id="picture"
                    accept="image/*"
                    type="file"
                    style="display: none"
                  />
                  <input
                    class="btn btn-danger ml-2 btn-sm"
                    type="reset"
                    @click="deletLoadeImage"
                    :value="$t('ShowCourseDetails.ANNULER')"
                  />
                  <input
                    class="btn btn-primary btn-sm ml-2"
                    type="submit"
                    :value="$t('ShowCourseDetails.APPLIQUER')"
                  />
                </form>
              </div>
            </div>
          </div>
          <!-- /Blog Sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import jwtDecode from "jwt-decode";
import EditPartModale from "@/components/shared/courses/EditPartModale.vue";
export default {
  components: {
    Navbar,
    EditPartModale,
  },
  data() {
    return {
      partCourse: [],
      titleofpart: "",
      course: {
        id: 0,
        slug: "",
        title: "",
        description: "",
        price: "",
        introVideo: "",
        currency: "",
        category: 0,
        coursePicture: {
          fullSize: "",
          thumbnail: "",
          mediumSquareCrop: "",
        },
        teacher: {
          id: 0,
          firstName: "",
          lastName: "",
          profilePicture: {
            mediumSquareCrop: "",
            fullSize: "",
            thumbnail: "",
          },
        },
        teacherProfile: {
          qualification: "",
          about: "",
        },
      },
      selectedContent: null,
      loadedImage: null,
      imgObject: null,
      reveleModale: false,
      indexedPart: 0,
      category: "",
      accessible: false,
      textVideo: "",
    };
  },
  computed: {
    getClassTitle() {
      if (this.titleofpart == "") {
        return "";
      } else {
        return "is-valid";
      }
    },
    isTeacher() {
      const access = localStorage.getItem("access");
      if (access == null) return false;
      return (
        jwtDecode(access).user_id == this.course.teacher.id ||
        this.$store.getters.userGroup == "admin"
      );
    },
  },
  props: {
    idCourse: String,
  },
  methods: {
    changeEditPartId(id) {
      this.selectedContent = null;
      this.indexedPart = id;
      this.reveleModale = true;
    },
    toggleModale() {
      if (this.isTeacher) {
        if (this.reveleModale == true) this.selectedContent = null;
        this.reveleModale = !this.reveleModale;
      }
    },
    setSelectedContent(content, partId) {
      this.selectedContent = content;
      this.indexedPart = partId;
      this.reveleModale = true;
    },
    async uploadImage() {
      if (this.loadedImage != null) {
        const supportedExtensions = ["jpeg", "jpg", "png", "gif"];
        const path = this.imgObject.name.split(".");
        const extension = `${path[path.length - 1]}`;
        if (supportedExtensions.includes(extension.toLowerCase())) {
          const fd = new FormData();
          fd.append("course_picture", this.imgObject, this.imgObject.name);
          await this.$http
            .put(`/courses/change-course-image/${this.idCourse}/`, fd)
            .then(() => {
              this.loadCourse();
              this.$toast.success("Success!");
              this.loadedImage = null;
              this.imgObject = null;
            })
            .catch(() => {
              this.$toast.error("Somthing wrong please try egain!");
            });
        } else {
          this.$toast.error("File not supported");
        }
      }
    },
    async deletePart(id) {
      if (window.confirm("Etes vous sure de cette action?")) {
        this.$http
          .delete(`/courses/course-part/update-delete/${id}/`)
          .then(() => {
            this.$toast.success("Success!");
            this.loadParts();
          })
          .catch((err) => {
            this.$toast.error("Something wrong!Please try egain!");
            console.log(err.response);
          });
      }
    },
    async deletePartContent({ id }) {
      if (window.confirm("Etes vous sure de cette action?")) {
        this.$http
          .delete(`/courses/course-content/${id}/`)
          .then(() => {
            this.$toast.success("Success!");
            this.loadParts();
          })
          .catch((err) => {
            this.$toast.error("Something wrong!Please try egain!");
            console.log(err.response);
          });
      }
    },
    beginCourse() {
      this.$router.push({ name: "ShowCourse", params: { id: this.idCourse } });
    },
    addCoursePart() {
      if (this.titleofpart == "") {
        this.error = true;
      } else {
        this.$http
          .post(`/courses/course-part/create`, {
            title: this.titleofpart,
            course: this.idCourse,
          })
          .then(() => {
            this.success = true;
            this.titleofpart = "";
            this.loadParts();
          });
      }
    },
    async loadParts() {
      await this.$http
        .get(`courses/${this.idCourse}/details/`)
        .then((res) => {
          this.partCourse = res.data.courseparts;
          this.course = res.data;
          this.loadCathegorie();
          this.checkIfEnrolled();
        })
        .catch((err) => {
          if (err.response.status == 404 || err.response.status == 400)
            this.$router.push({
              name: "NotFound",
              params: { pathMatch: "not-found" },
            });
          else this.$toast.error("An error occured!");
        });
    },
    loadImage(event) {
      this.loadedImage = URL.createObjectURL(event.target.files[0]);
      this.imgObject = event.target.files[0];
    },
    deletLoadeImage() {
      this.loadedImage = null;
    },
    async loadCathegorie() {
      await this.$http
        .get(`/courses/category/${this.course.category}/`)
        .then((res) => (this.category = res.data.name));
    },
    async checkIfEnrolled() {
      if (this.$store.getters.loggedIn === false) this.accessible = false;
      else
        await this.$http(
          `/enrolment/check-user-course-access/${this.course.id}`
        ).then((response) => {
          this.accessible = response.data;
        });
    },
  },
  beforeCreate() {
    this.$nextTick(function () {
      this.loadParts();
    });
  },
};
</script>

<style>
.img-preview {
  width: 100px;
  height: 100px;
}
.list-group .list-group {
  display: none;
  margin-bottom: 0;
}

.list-group-item:focus-within + .list-group {
  display: block;
}

.list-group .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group .list-group .list-group-item {
  padding-left: 2.5rem;
}
.text-link {
  cursor: pointer;
}
.rrounded-circle {
  border-radius: 50%;
}
</style>
